import React, { ComponentProps } from 'react'
import styled from 'styled-components'

export type NavMenuProps = ComponentProps<typeof StyledNavMenu> & {
  //
}

export const StyledNavMenu = styled.div`
  list-style-type: none;
  margin: 0 ${props => props.theme.typography.pxToRem(-10)};
  padding: 0;
  overflow: hidden;

  ${props => props.theme.breakpoints.up('lg')} {
    margin: 0 ${props => props.theme.typography.pxToRem(-30)};
  }
`

export const NavMenu: React.FC<NavMenuProps> = (props) => {
  return (
    <StyledNavMenu {...props} />
  )
}
