import React, { ComponentProps, useContext } from 'react'
import { Container, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { StyledNavItem } from '../PrimaryNavigation/NavItem'
import { PrimaryNavigationContext } from '../PrimaryNavigation/PrimaryNavigation'
import { rem } from '../../utils'

export type NavDropdownPanelProps = ComponentProps<typeof Grid> & {
  show?: boolean
}

export const NavDropdownPanel: React.FC<NavDropdownPanelProps> = ({ show=false, children, ...props }) => {
  const { secondary } = useContext(PrimaryNavigationContext)

  return (
    <StyledPanelWrapper $show={show} $secondary={secondary}>
      <Container maxWidth={'lg'}>
        <Grid container justifyContent={'flex-end'}>
          <Grid item xs md={11}>
            <StyledDropdownPanel container $show={show} direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={8} {...props}>
              { children }
            </StyledDropdownPanel>
          </Grid>
        </Grid>
      </Container>
    </StyledPanelWrapper>
  )
}

const StyledPanelWrapper = styled.div<{ $show?: boolean; $secondary?: boolean }>`
  display: ${props => props.$show ? 'block' : 'none'};
  position: absolute;
  top: ${props => rem(props.$secondary ? 104 : 120, props.theme)};
  left: 0;
  right: 0;
  z-index: 9;
  background: #f8f8f8;

  ${StyledNavItem}:hover > & {
    display: block;
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding-bottom: ${props => props.theme.typography.pxToRem(45)};
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 45px), 0 100%);
  }

  ${props => props.theme.breakpoints.up('lg')} {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 90px), 0 100%);
    padding-bottom: ${props => props.theme.typography.pxToRem(90)};
  }
`

export const StyledDropdownPanel = styled(Grid)<{$show?: boolean}>`
  cursor: default;
  padding: 75px 0 100px;

  > .MuiGrid-item {
    width: 100%;
  }
`